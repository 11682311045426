// Override default variables before the import
$body-bg: #fff;

//$primary: #2c7be5;

$h2-font-size: 20px;
$h3-font-size: 15px;
$h4-font-size: 12px;

$input-placeholder-color: rgb(200, 200, 200);

$btn-font-size-lg: 15px;
$btn-border-radius-lg: 6px;

$card-border-color: rgb(237, 242, 249);

//$table-accent-bg: rgb(249, 251, 253);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
